<template>
    <div>
        <Navbar page="Assinatura" />
        <div class="mx-4 my-4 md:mx-8 md:my-8">
            <h1 class="font-r font-medium text-3xl mb-8">Assinatura Fácil LGPD</h1>
            <h3 v-if="$store.state.user.data && $moment($store.state.user.data).add(15,'days').endOf('days').diff($moment().endOf('days'), 'days') >= 0" class="text-gray-800 font-r font-medium text-xl mb-8">Dias de Teste Gratis disponível: {{ $moment($store.state.user.data).add(15,'days').endOf('days').diff($moment().endOf('days'), 'days') > 0 ? $moment($store.state.user.data).add(15,'days').endOf('days').diff($moment().endOf('days'), 'days') : 'Finalizado' }} </h3>
            <div class="grid grid-cols-12 gap-6">
                <div class="col-span-12 2xl:col-span-8">
                    <div class="border border-gray-100 shadow-lg rounded py-5 px-4">
                        <h3 class="text-gray-800 font-r font-medium text-xl mb-3">Seus dados </h3>
                        <div class="grid grid-cols-12 gap-6">
                            <div class="col-span-12 md:col-span-8">
                                <label for="name" class="block text-sm font-medium">Nome Completo</label>
                                <input v-model="form.name" type="text" name="name" id="name" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-lg">
                            </div> 
                            <div class="col-span-12 md:col-span-4">
                                <label for="dataNasc" class="block text-sm font-medium">Data de Nascimento</label>
                                <the-mask :mask="['##/##/####']" v-model="form.dataNasc" id="dataNasc" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-lg"/>
                            </div>
                            <div class="col-span-12 md:col-span-4">
                                <label for="phone" class="block text-sm font-medium">Telefone Celular</label>
                                <the-mask :mask="['(##) ####-####', '(##) #####-####']" v-model="form.phone" placeholder="(99) 99999-9999"  id="phone" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-lg"/>
                            </div> 
                        </div>
                        <h3 class="text-gray-800 font-r font-medium text-xl my-3">Documento </h3>
                        <div class="grid grid-cols-12 gap-6">
                            <div class="col-span-12 md:col-span-4">
                                <label for="cpf" class="block text-sm font-medium">CPF</label>
                                <the-mask :mask="['###.###.###-##']" v-model="form.cpf" id="cpf" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-lg"/>
                            </div> 
                        </div>
                        <h3 class="text-gray-800 font-r font-medium text-xl my-3">Endereço </h3>
                        <div class="grid grid-cols-12 gap-6 mt-5">
                            <div class="col-span-12 md:col-span-4">
                                <label for="cep" class="block text-sm font-medium">CEP</label>
                                <the-mask @blur.native="buscaCep" :mask="['#####-###']" v-model="form.endereco.cep" id="cep" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-lg"/>
                            </div> 
                            <div class="col-span-12 md:col-span-6">
                                <label for="logradouro" class="block text-sm font-medium">Logradouro</label>
                                <input v-model="form.endereco.logradouro" type="text" name="logradouro" id="logradouro" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-lg">
                            </div>
                            <div class="col-span-12 md:col-span-2">
                                <label for="numero" class="block text-sm font-medium">Número</label>
                                <input v-model="form.endereco.numero" type="text" name="numero" id="numero" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-lg">
                            </div>
                            <div class="col-span-12 md:col-span-4">
                                <label for="bairro" class="block text-sm font-medium">Bairro</label>
                                <input v-model="form.endereco.bairro" type="text" name="bairro" id="bairro" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-lg">
                            </div>
                            <div class="col-span-12 md:col-span-4">
                                <label for="cidade" class="block text-sm font-medium">Cidade</label>
                                <input v-model="form.endereco.cidade" type="text" name="cidade" id="cidade" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-lg">
                            </div> 
                            <div class="col-span-12 md:col-span-4">
                                <label for="estado" class="block text-sm font-medium">Estado</label>
                                <input v-model="form.endereco.estado" type="text" name="estado" id="estado" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-lg">
                            </div> 
                        </div>
                        <hr class="my-5" />
                        <div class="grid grid-cols-12">
                            <div class="col-span-12 md:justify-self-end">
                                <button @click="avancar" type="button" class="text-white hover:bg-green-500 bg-green-700 rounded py-2 px-4">
                                    Salvar e Avançar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-span-12 md:col-span-7 2xl:col-span-4">
                    <div v-if="plano" class="border border-gray-100 shadow-lg rounded py-5 px-4">
                        <h3 class="text-gray-800 text-center font-r font-medium text-2xl">{{ plano && plano.name ? plano.name : '' }} <span v-if="plano.interval === 'year'" class="text-red-500 text-lg">20% de desconto</span></h3>
                        <hr class="my-5" />
                        <p class="text-gray-700 font-r font-medium text-base py-1">
                            <svg xmlns="http://www.w3.org/2000/svg" class="inline h-4 w-4 mx-2" fill="none" viewBox="0 0 24 24"
                                stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                            </svg>
                            Multiprojetos (ilimitados projetos ao mesmo tempo) 
                        </p>
                        <p class="text-gray-700 font-r font-medium text-base py-1">
                            <svg xmlns="http://www.w3.org/2000/svg" class="inline h-4 w-4 mx-2" fill="none" viewBox="0 0 24 24"
                                stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                            </svg>
                            Múltiplos profissionais no projetos 
                        </p>
                        <p class="text-gray-700 font-r font-medium text-base py-1">
                            <svg xmlns="http://www.w3.org/2000/svg" class="inline h-4 w-4 mx-2" fill="none" viewBox="0 0 24 24"
                                stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                            </svg>
                            White Label (sua marca e suas cores na plataforma)
                        </p>
                        <p class="text-gray-700 font-r font-medium text-base py-1">
                            <svg xmlns="http://www.w3.org/2000/svg" class="inline h-4 w-4 mx-2" fill="none" viewBox="0 0 24 24"
                                stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                            </svg>
                            Assinatura mensal, cancela quando quiser
                        </p>
                        <p class="text-gray-700 font-r font-medium text-base py-1">
                            <svg xmlns="http://www.w3.org/2000/svg" class="inline h-4 w-4 mx-2" fill="none" viewBox="0 0 24 24"
                                stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                            </svg>
                            Sem multas ou asteriscos 
                        </p>
                        <hr class="my-5" />
                        <p class="mx-2 text-green-700 font-r font-semibold text-2xl">{{ formatPrice(plano.minimum_price / 100) }} </p>
                    </div>
                </div>
            </div>
            <modal name="modalPagamento" :adaptive="true" :height="'auto'" @closed="form.payment_method = ''">
                <div class="px-5 py-5">
                    <h2 v-if="!form.payment_method && plano && plano.name" class="font-r font-medium text-2xl"> {{ plano && plano.name ? plano.name : '' }}</h2>
                    <p v-if="!form.payment_method && plano && plano.minimum_price" class="text-green-700 font-r font-semibold text-2xl">{{ formatPrice(plano.minimum_price / 100) }} </p>
                    <h2 v-if="!form.payment_method" class="font-r font-medium text-xl mt-3 mb-5"> Escolha a forma de pagamento: </h2>
                    <div v-if="!form.payment_method">
                        <button @click="form.payment_method = 'credit_card'" type="button" class="text-lg text-white hover:bg-green-500 bg-terciary rounded py-5 w-full my-1">
                                Cartão de Crédito
                                <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                        </button>
                        <button @click="assinar('boleto')" type="button" class="text-lg text-white hover:bg-green-500 bg-terciary rounded py-5 w-full my-1">
                                Boleto
                                <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                        </button>
                    </div>
                    <div v-if="form.payment_method === 'credit_card'">
                            <h2 class="font-r font-medium text-2xl mb-5"> Informe os dados do seu cartão: </h2>
                            <vue-paycard :value-fields="valueFields" :isCardNumberMasked="false" :labels="{ cardName : 'Nome Completo', cardHolder : 'Nome no Cartão', cardMonth : 'MM', cardYear : 'YYYY', cardExpires : 'Expira', cardCvv : 'CVV'}" />
                            <form class="grid grid-cols-12 gap-4 mt-5">
                                <div class="col-span-12 md:col-span-12">
                                    <label class="block text-base font-medium">Número do cartão</label>
                                    <input @change="changeForm" v-model="form.card.number" class="mt-2 px-2 border-2 border-gray-300 rounded-lg w-full h-10 shadow-sm focus:ring-yellow-400 focus:border-yellow-400" v-cardformat:formatCardNumber>
                                </div>
                                <div class="col-span-12 md:col-span-8">
                                    <label class="block text-base font-medium">Validade do cartão</label>
                                    <input @change="changeForm" v-model="form.card.cardExpiry" placeholder="01/2022" class="mt-2 px-2 border-2 border-gray-300 rounded-lg w-full h-10 shadow-sm focus:ring-yellow-400 focus:border-yellow-400" v-cardformat:formatCardExpiry>
                                </div>
                                <div class="col-span-12 md:col-span-4">
                                    <label class="block text-base font-medium">CVV</label>
                                    <input @change="changeForm" v-model="form.card.cvv" class="mt-2 px-2 border-2 border-gray-300 rounded-lg w-full h-10 shadow-sm focus:ring-yellow-400 focus:border-yellow-400" v-cardformat:formatCardCVC>
                                </div>
                                <div class="col-span-12 md:col-span-12">
                                    <label class="block text-base font-medium">Nome no Cartão</label>
                                    <input @change="changeForm" v-model="form.card.holder_name" class="mt-2 px-2 border-2 border-gray-300 rounded-lg w-full h-10 shadow-sm focus:ring-yellow-400 focus:border-yellow-400">
                                </div>
                                <div class="col-span-12 md:col-span-12">
                                    <button @click="assinar('credit_card')" type="button" class="text-white hover:bg-green-500 bg-green-700 rounded py-2 px-6">
                                        Pagar Assinatura
                                    </button>
                                </div>
                            </form>
                    </div>
                </div>
            </modal>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            route: 'pagarme',
            user: null,
            plano: null,
            valueFields: {
                cardName: "",
                cardNumber: "",
                cardMonth: "",
                cardYear: "",
                cardCvv: "",
            },
            form: {
                payment_method: '',
                card: {
                    number: null,
                    holder_name: "",
                    cardExpiry: null,
                    cvv: null,
                },
                endereco: {
                    cidade: '',
                    cep: '',
                    estado: '',
                    endereco: '',
                    numero: '',
                    bairro: '',
                },
                name: "",
                cpf: "",
                phone: "",
                dataNasc: "",
            }
        }
    },
    methods: {
        changeForm () {
            if(this.form.card.number) this.valueFields.cardNumber = this.cc_format(this.form.card.number);
            if(this.form.card.cvv) this.valueFields.cardCvv = this.form.card.cvv;
            if(this.form.card.holder_name) this.valueFields.cardName = this.form.card.holder_name;

            let cardExpiry = this.form.card.cardExpiry ? this.form.card.cardExpiry.replace(/\s/g, '') : '';
            if(!cardExpiry.match('/')) return;

            cardExpiry = cardExpiry.split('/');

            if(!cardExpiry[0] || !cardExpiry[1] || cardExpiry[0].length !== 2 || cardExpiry[1].length !== 4) return;
            this.valueFields.cardMonth = cardExpiry[0];
            this.valueFields.cardYear = cardExpiry[1];

        },
        async start() {

            const planoReq = await this.$http.get(`/v1/${this.route}/${this.$route.params.id}`);
            this.plano = planoReq.data;
        
            const userReq = await this.$http.post('/v1/users/me');
            this.user = userReq.data;
            if(userReq.data.endereco) this.form.endereco = userReq.data.endereco;
            if(userReq.data.cpf) this.form.cpf = userReq.data.cpf;
            if(userReq.data.name) this.form.name = userReq.data.name;
            if(userReq.data.phone) this.form.phone = userReq.data.phone;
            if(userReq.data.dataNasc) this.form.dataNasc = this.$moment(userReq.data.dataNasc).format('DD/MM/YYYY');
        },

        async assinar(payment_method) {

            let loader = this.$loading.show({
                container: null,
                canCancel: true
            });

            this.form.plano = this.plano;
            this.form.user = this.user;
            this.form.payment_method = payment_method;

            if(payment_method === 'credit_card') {
                
                let cardExpiry = this.form.card.cardExpiry ? this.form.card.cardExpiry.replace(/\s/g, '') : '';
                if(!cardExpiry.match('/')) {
                    loader.hide();
                    this.$vToastify.error("Data de validade inválida");
                    return
                }

                cardExpiry = cardExpiry.split('/');

                if(!cardExpiry[0] || !cardExpiry[1] || cardExpiry[0].length !== 2 || cardExpiry[1].length !== 4) {
                    loader.hide();
                    this.$vToastify.error("Data de validade inválida, formato correto: 01/2022");
                    return
                }
                this.form.card.exp_month = cardExpiry[0];
                this.form.card.exp_year = cardExpiry[1].substr(2);

                delete this.form.card.cardExpiry;
                
                this.form.card.holder_document = this.user.cpf;
                this.form.card.holder_name = this.form.card.holder_name.toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');

                if(!this.$cardFormat.validateCardNumber(this.form.card.number)) {
                    loader.hide();
                    this.$vToastify.error("Cartão inválido");
                    return
                } 
                if(!this.$cardFormat.validateCardCVC(this.form.card.cvv)) {
                    loader.hide();
                    this.$vToastify.error("Cartão inválido");
                    return 
                }

                this.form.card.number = this.form.card.number.replace(/[^0-9]/g,'');
            }
            
            const resp = await this.$http.post(`/v1/pagarme/assinar`, this.form);

            if (resp.data.success) {
                this.$vToastify.success("Processando Assinatura");
                loader.hide();
                this.$router.push({path: `/assinatura`});
            } else {
                loader.hide();
                this.$vToastify.error(resp.data.err);
            }

            loader.hide();

        },

        async avancar() {
            if(!this.form.name) return this.$vToastify.error("Nome completo é obrigatório");
            if(!this.form.cpf) return this.$vToastify.error("CPF é obrigatório");
            if(!this.form.phone) return this.$vToastify.error("Telefone celular é obrigatório");
            if(!this.form.dataNasc) return this.$vToastify.error("Data de nascimento é obrigatório");
            if(!this.form.endereco.logradouro) return this.$vToastify.error("Endereço é obrigatório");

            const req = await this.$http.put('/v1/users/',{ _id: this.user._id, name: this.form.name, endereco: this.form.endereco, cpf: this.form.cpf, phone: this.form.phone, dataNasc: this.$moment(this.form.dataNasc, "DD/MM/YYYY").toISOString() });
            this.user = req.data.data;
            this.$modal.show('modalPagamento');
        },

        async buscaCep() {
            if (!this.form.endereco.cep) return;
            const response = await this.$http.get(`/v1/cep/${this.form.endereco.cep}`);
            if (response.data.erro === undefined) {
                this.form.endereco.cidade = response.data.localidade;
                this.form.endereco.estado = response.data.uf;
                this.form.endereco.logradouro = response.data.logradouro;
                this.form.endereco.bairro = response.data.bairro;
            }   
        },

        cc_format(value) {
            var v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '')
            var matches = v.match(/\d{4,16}/g);
            var match = matches && matches[0] || ''
            var parts = []

            for (let i=0, len=match.length; i<len; i+=4) {
                parts.push(match.substring(i, i+4))
            }

            if (parts.length) {
                return parts.join(' ')
            } else {
                return value
            }
        }
    },
    async beforeMount() {
        this.start();
    }
}
</script>
